import Vue from 'vue';

Vue.filter('timestampToDateString', (timestamp: number) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options);
});

Vue.filter('dateToDateString', (dateString: string) => {
  const timestamp = Date.parse(dateString);
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options);
});
Vue.filter('timestampToString', (timestamp: number) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options);
});
